// import Intro from './Intro'
import Fail from './Fail'

export function App() {
  return (
    <>
      <main>
        <Fail />
      </main>
    </>
  )
}