import {EfLogo} from './components/logos'
import Footer from './components/Footer'
import Header from './components/Header'
import DataLinks from './components/DataLinks'
import SocialMediaBlock from './components/social-media'
import PollWorkerBlock from './components/PollWorkerBlock'
import SignupMail from './components/SignupMail'

const socialLinks = [
  // {title: 'Telegram', type:'telegram', link:'https://t.me/NEAuditChat'},
  {title: 'Telegram', type:'telegram', link:'https://t.me/NebraskaVAP'},
  {title: 'Facebook', type:'facebook', link: 'https://www.facebook.com/NEVoterAP'},
  {title: 'Twitter', type:'twitter', link: 'https://twitter.com/NeVoterAP'},
  {title: 'Gettr', type:'gettr', link: 'https://gettr.com/user/nevoterap'},
  {title: 'Gab', type:'gab', link: 'https://gab.com/NeVoterAP'},
  {title: 'Rumble', type:'rumble', link: 'https://rumble.com/c/c-1270342'},
  {title: 'Soundcloud', type:'soundcloud', link: 'https://soundcloud.com/nvap'},
]

export const Intro = () => {
  return (
    <div className='intro-page'>
      <Header />
      <div className="main-content">
        
        <section className='primary-intro-new'>
            <h3>Trump Tricked Us :(</h3>
            <p>Let's face it, we were duped by Trump. He lost in 2020 fair and square and we believed him because we wanted to. 
            Now he's using tariffs to tax <b>US</b> in order to pay for tax cuts for his WEALTHY donors.</p>
            <p>We're sorry that we were an unwitting part of this lie.</p>
            <p><em>Got help us all.</em></p>
        </section>

      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Intro